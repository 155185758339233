import React from 'react'
import PropTypes from 'prop-types'

import { SectionRouter } from '@/components/SectionRouter'
import { Container } from '@/components/Container'
import { Link } from '@/components/Link'

import { Image } from '@/sections/Image'
import { SmallImage } from '@/sections/SmallImage'
import { BodyText } from '@/sections/BodyText'
import { Hero } from '@/sections/Hero'
import { Reviews } from '@/sections/Reviews'
import { PageLinks } from '@/sections/PageLinks'
import { Video } from '@/sections/Video'

import * as styles from './BlogPostContent.styles.scss'

const BlogPostContent = ({ title, date, parent, sections, link }) => {
  return (
    <div>
      <Container>
        <div>
          <div className={styles.head}>
            {parent?.path && (
              <div className={styles.back}>
                <Link to={parent.path}>Back to {parent.title}</Link>
              </div>
            )}
            <h2 className={styles.heading}>
              <Link to={link}>{title}</Link>
            </h2>
            <div className={styles.date}>{date}</div>
          </div>
          <SectionRouter
            sections={sections}
            types={{
              hero: Hero,
              body_text: BodyText,
              big_image: Image,
              small_image: SmallImage,
              reviews: Reviews,
              page_links: PageLinks,
              video: Video,
            }}
          />
        </div>
      </Container>
    </div>
  )
}

BlogPostContent.defaultProps = {
  isActive: false,
  onClick: () => {},
  link: undefined,
}

BlogPostContent.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  link: PropTypes.string,
}

export { BlogPostContent }
