import React from 'react'
import PropTypes from 'prop-types'
import { BlogPostContent } from '@/components/BlogPostContent'
import * as styles from './BlogPost.styles.scss'

const BlogPost = (props) => {
  return (
    <div className={styles.BlogPost}>
      <BlogPostContent {...props} />
    </div>
  )
}

BlogPost.defaultProps = {
  title: '',
  date: '',
  parent: {},
  sections: [],
}

BlogPost.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  parent: PropTypes.object,
  sections: PropTypes.arrayOf(PropTypes.object),
}

export default BlogPost
