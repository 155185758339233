import React from 'react'
import PropTypes from 'prop-types'
import { withPreview } from 'gatsby-source-prismic'
import { graphql } from 'gatsby'
import { get } from '@/utils/get'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import BlogPost from '@/templates/BlogPost'

const BlogPostPrismic = ({ data, pageContext }) => {
  const { prismicBlogPost } = data
  const title = get(prismicBlogPost, 'data.title')

  return (
    <BlogPost
      title={title}
      date={prismicBlogPost.data.date}
      parent={pageContext.parent}
      sections={sectionSerializer(prismicBlogPost.data.body)}
    />
  )
}

BlogPostPrismic.defaultProps = {
  data: {},
  pageContext: {},
}

BlogPostPrismic.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withPreview(BlogPostPrismic)

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      uid
      type
      data {
        title
        date(formatString: "DD MMM YYYY", locale: "en-GB")
        body {
          ... on PrismicBlogPostBodyBodyText {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicBlogPostBodyBigImage {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 2500) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                raw
              }
            }
          }
          ... on PrismicBlogPostBodySmallImage {
            id
            slice_type
            primary {
              image {
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicBlogPostBodyVideo {
            id
            slice_type
            primary {
              url
              aspect_ratio
            }
          }
        }
      }
    }
  }
`
